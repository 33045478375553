import React, { useState, useEffect } from 'react';
import { GetData } from '../api/setData';
import { saveAs } from 'file-saver';

const Export = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        GetData({ setData });
    }, []);

    const renderTableData = () => {
        return data.map((dataInfo, index) => {
            const rowValues = Object.values(dataInfo);
            const rowArray = rowValues.map((value) => String(value)); // Convert values to strings
            return (
                <tr key={index}>
                        <>
                            <th>{index +1 }</th>
                    {rowArray.map((value, idx) => (
                        <td key={idx} className={`text-nowrap ${!value || value === 'null' && 'bg-dark'}`}>
                            {value}
                        </td>
                    ))}
                        </>
                </tr>
            );
        });
    };

    const renderTableHeader = () => {
        if (data.length > 0) {
            const headers = Object.keys(data[0]);
            return (
                <tr>  
                    <>
                <th>#</th>
                    {headers.map((header, index) => (
                            <th key={index} className='text-nowrap'>
                                {header}
                            </th>
                    ))}
                    </>
                </tr>
            );
        }
        return null;
    };

    const [csvData, setCsvData] = useState('');

    const handleExport = () => {
        let csvContent = '';

        // Generate the CSV content
        const headerRow = renderTableHeader();
        if (headerRow) {
            const headerArray = Array.from(headerRow.props.children).map((header) => header.props.children);
            csvContent += headerArray.join(',') + '\n';
        }

        data.forEach((row) => {
            const rowArray = Object.values(row).map((value) => String(value)); // Convert values to strings
            csvContent += rowArray.join(',') + '\n';
        });

        setCsvData(csvContent);

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'فوج اطفاء بيروت.csv');
    };

    return (
        <>
            <div className='align-items-center justify-content-center table-responsive vh-100' dir='rtl'>
                <button onClick={handleExport}>Export to Excel</button>
                {data.length > 0 && (
                    <table id='data-table' className='table table-bordered'>
                        <thead>{renderTableHeader()}</thead>
                        <tbody className>{renderTableData()}</tbody>
                    </table>
                )}
            </div>
        </>
    );
};

export default Export;
