import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'; 
import { 
  setPersonInfo, 
  setCarInfo, 
  setpSicknesses,
  
  setChildsInfo,
  setSpouseInfo,
  setFatherInfo,
  setMotherInfo

} from '../api/setData'; 

const SaveInfos = () => {

  const navigate = useNavigate();

  const personalInfo = JSON.parse(localStorage.getItem('personInfo'));
  const carInfo = JSON.parse(localStorage.getItem('carInfo'));
  const pSicknesses = JSON.parse(localStorage.getItem('personSicknesses'));

  const fatherInfo = JSON.parse(localStorage.getItem('fatherInfo'));
  const fatherDaman = JSON.parse(localStorage.getItem('fatherDaman'));
  const fatherSicknesses = JSON.parse(localStorage.getItem('fatherSicknesses'));

  const motherInfo = JSON.parse(localStorage.getItem('motherInfo'));
  const motherDaman = JSON.parse(localStorage.getItem('motherDaman'));
  const motherSicknesses = JSON.parse(localStorage.getItem('motherSicknesses'));

  const spouseInfo = JSON.parse(localStorage.getItem('spouseInfo'));
  const spouseDaman = JSON.parse(localStorage.getItem('spouseDaman'));
  const spouseSicknesses = JSON.parse(localStorage.getItem('spouseSicknesses'));

  const childsInfo = JSON.parse(localStorage.getItem('childInfo')); 
 
  const saveData = () => {
    setPersonInfo(personalInfo);
    setCarInfo(carInfo);
    setpSicknesses(pSicknesses);
    setChildsInfo(childsInfo);
    setSpouseInfo(spouseInfo, spouseDaman, spouseSicknesses);
    setFatherInfo(fatherInfo, fatherDaman, fatherSicknesses);
    setMotherInfo(motherInfo, motherDaman, motherSicknesses);
  
    setTimeout(() => {
      removeItemsFromLocalStorage();
    }, 2000); // Wait for 2000 milliseconds (2 seconds) before removing items from local storage
  };
  

  function removeItemsFromLocalStorage() {
    // List of item keys to remove
    const itemKeys = [
      'personInfo',
      'carInfo',
      'personSicknesses',

      'fatherInfo',
      'fatherDaman',
      'fatherSicknesses',

      'motherInfo',
      'motherDaman',
      'motherSicknesses',

      'spouseInfo',
      'spouseDaman',
      'spouseSicknesses',

      'childInfo', 
    ];

    // Loop through the item keys and remove them from local storage
    itemKeys.forEach(key => {
      localStorage.removeItem(key);
    });

    
    navigate('/End');
  }
    
  useEffect(() => {
    if (!personalInfo || personalInfo.married && !spouseInfo || !fatherInfo || !motherInfo ) {
          navigate('/Person');
    }

}, [personalInfo, navigate,spouseInfo ,fatherInfo, motherInfo]);
  return (
    <>

      <div className="   text-center align-items-center justify-content-center mt-3 " dir='rtl'>
        <Container>
          <Row className="justify-content-md-center">
            <Col md="auto"><h5 className='text-dark'>اذا احدى المعلومات غير صحيحة انقر </h5></Col>
          </Row>
          <Row className="justify-content-md-center">
            <button type="button" className="btn btn-primary" onClick={() => navigate('/Person')}>
              تعديل
            </button>
          </Row>
        </Container>
{/* 
        <Container>
          <Row className="justify-content-md-center">
            <Col md="auto"><h5 className='text-dark'>اذا تردت اعادة تعبئة جميع المعلومات من جديد انقر</h5></Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <button type="button" className="btn btn-primary m-1" onClick={removeItemsFromLocalStorage}>
                مسح
              </button></Col>
          </Row>
        </Container> */}

        <Container className='mt-3'>
          <Row className="justify-content-md-center text-bg-danger">
            <Col md="auto"><h5 className='text-dark'>يرجى التاكد من صحة جميع المعلومات قبل الحفظ  </h5></Col>
          </Row>
        </Container>
        <Container className='mt-5'>

          <Row className="justify-content-md-center">
            <Col md="auto"><h2 className='text-dark'>معلومات شخصية</h2></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> الرقم المالي </h3></Col>
            <Col><h3>{personalInfo && personalInfo.nofi}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger '> الرتبة </h3></Col>
            <Col><h3>{personalInfo && personalInfo.degree.label}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> الاسم </h3></Col>
            <Col><h3>{personalInfo && personalInfo.firstname}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> الشهرة </h3></Col>
            <Col><h3>{personalInfo && personalInfo.lastname}</h3></Col>
          </Row>

          <Row>
            <Col><h3 className='text-danger'> الهاتف </h3></Col>
            <Col><h3>{personalInfo && personalInfo.phonenumber}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger '> فئة الدم </h3></Col>
            <Col><h3>{personalInfo && personalInfo.blood.value}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> مكان الولادة </h3></Col>
            <Col><h3>{personalInfo && personalInfo.birthplace}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> تاريخ الولادة </h3></Col>
            <Col><h3>{personalInfo && personalInfo.birthdate}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> المستوى العلمي </h3></Col>
            <Col><h3>{personalInfo && personalInfo.educationalLevel.label}</h3></Col>
          </Row>

          <Row>
            <Col><h3 className='text-danger '> المحافظة </h3></Col>
            <Col><h3>{personalInfo && personalInfo.governorate.label}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> القضاء </h3></Col>
            <Col><h3>{personalInfo && personalInfo.district.label}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> مكان السجل </h3></Col>
            <Col><h3>{personalInfo && personalInfo.recordPlace}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> رقم السجل </h3></Col>
            <Col><h3>{personalInfo && personalInfo.recordNumber}</h3></Col>
          </Row>

          <Row>
            <Col><h3 className='text-danger '> المدينة </h3></Col>
            <Col><h3>{personalInfo && personalInfo.city}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> المنطقة </h3></Col>
            <Col><h3>{personalInfo && personalInfo.region}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> شارع </h3></Col>
            <Col><h3>{personalInfo && personalInfo.street}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> بناية </h3></Col>
            <Col><h3>{personalInfo && personalInfo.building}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> طابق </h3></Col>
            <Col><h3>{personalInfo && personalInfo.floor}</h3></Col>
          </Row>

          {carInfo && carInfo.hasCar?
            <>
              {/* <h3 className='text-danger '> الرقم المالي </h3><h3>{carInfo && carInfo.nofi}</h3> */}
              <Row>
                <Col><h3 className='text-danger '> طراز السيارة </h3></Col>
                <Col><h3>{carInfo && carInfo.carBrand}</h3></Col>
              </Row>
              <Row>
                <Col><h3 className='text-danger'> رمز السيارة </h3></Col>
                <Col><h3>{carInfo && carInfo.carCode.label}</h3></Col>
              </Row>
              <Row>
                <Col><h3 className='text-danger'> رقم السيارة </h3></Col>
                <Col><h3>{carInfo && carInfo.carNumber}</h3></Col>
              </Row>
            </>
            :
            <Row>
              <Col><h3 className='text-danger'> سيارة </h3></Col>
              <Col><h3> لا يوجد  </h3></Col>
            </Row>
          }

          {/* <Row>
            <Col><h3 className='text-danger '> الرقم المالي </h3></Col>
            <Col><h3>{pSicknesses && pSicknesses.nofi}</h3></Col>
          </Row> */}
          {pSicknesses && pSicknesses.personSicknesses ? pSicknesses.personSicknesses.map((personSicknes, i) => (

            <Row>
              <Col><h3 className='text-danger '> المرض{i + 1} </h3></Col>
              <Col><h3>{personSicknes}</h3> </Col>
            </Row>
          ))
            :
            <Row>
              <Col><h3 className='text-danger'>امراض</h3></Col>
              <Col><h3> لا يوجد  </h3></Col>
            </Row>
          }
        </Container>


        {/* Childs  */}
        <Container className='mt-5'>
          <Row className="justify-content-md-center">
            <Col md="auto"><h2 className='text-dark'>معلومات عن الاولاد</h2></Col>
          </Row>
              <Row>
                <Col><h3 className='text-dark'> عدد الاولاد {childsInfo && childsInfo.length}</h3></Col>
              </Row>


          {childsInfo ? childsInfo.map((child, i) => (
            <>
              <Row>
                <Col><h3 className='text-warrning '> الولد {i +1} </h3></Col> 
              </Row>
              <Row>
                <Col><h3 className='text-danger '> اسم </h3></Col>
                <Col><h3>{child.name}</h3> </Col>
              </Row>
              <Row>
                <Col><h3 className='text-danger '> شهرة </h3></Col>
                <Col><h3>{child.lastName}</h3> </Col>
              </Row>
              <Row>
                <Col><h3 className='text-danger '> تاريخ ولادة </h3></Col>
                <Col><h3>{child.birthdate}</h3> </Col>
              </Row>

              {child.inn ?
                  <> 
                    <Row>
                      <Col><h3 className='text-danger '> الضمان </h3></Col>
                      <Col><h3>{child && child.inn}</h3></Col>
                    </Row>
                  </>
                  :
                  <Row>
                    <Col><h3 className='text-danger '> ضمان </h3></Col>
                    <Col><h3> لا يوجد  </h3></Col>
                  </Row>
                }

{child && child.hasSickness ?
                  <>
                    {child && child.sicknessess.map((sicknes, i) => (
                      <Row>
                        <Col><h3 className='text-danger '> المرض{i + 1}  </h3></Col>
                        <Col><h3> {sicknes}  </h3></Col>
                      </Row>
                    ))
                    }
                  </>
                  :
                  <Row>
                    <Col><h3 className='text-danger '> امراض</h3></Col>
                    <Col><h3> لا يوجد امراض</h3></Col>
                  </Row>
                } 
              
            </>
          ))
            :
            <Row>
              <Col><h3 className='text-danger'>اولاد</h3></Col>
              <Col><h3> لا يوجد  </h3></Col>
            </Row>
          }  

                
        </Container>
        {/* Childs  */}

        {/* spouse  */}
        <Container className='mt-5'>
          <Row className="justify-content-md-center">
            <Col md="auto"><h2 className='text-dark'>معلومات عن الزوج/ة</h2></Col>
          </Row>
          {personalInfo && personalInfo.married ? 
          <>
          <Row>
            <Col><h3 className='text-danger'> اسم الزوج/ة</h3></Col>
            <Col><h3>{spouseInfo && spouseInfo.firstname}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> شهرة الزوج/ة</h3></Col>
            <Col><h3>{spouseInfo && spouseInfo.lastname}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> تاريخ ولادة الزوج/ة</h3></Col>
            <Col><h3>{spouseInfo && spouseInfo.birthdate}</h3></Col>
          </Row>

          {
            spouseInfo && spouseInfo.isalive ?
              <>
                {spouseDaman.daman ?
                  <>
                    {/* <Row>
                      <Col><h3 className='text-danger '> الرقم المالي </h3></Col>
                      <Col><h3>{spouseDaman && spouseDaman.nofi}</h3></Col>
                    </Row> */}
                    <Row>
                      <Col><h3 className='text-danger '> الضمان </h3></Col>
                      <Col><h3>{spouseDaman && spouseDaman.daman}</h3></Col>
                    </Row>
                  </>
                  :
                  <Row>
                    <Col><h3 className='text-danger '> ضمان </h3></Col>
                    <Col><h3> لا يوجد  </h3></Col>
                  </Row>
                }

                {spouseSicknesses && spouseSicknesses.hasSickness ?
                  <>
                    {spouseSicknesses && spouseSicknesses.spouseSicknesses.map((spouseSicknes, i) => (
                      <Row>
                        <Col><h3 className='text-danger '> المرض{i + 1}  </h3></Col>
                        <Col><h3> {spouseSicknes}  </h3></Col>
                      </Row>
                    ))
                    }
                  </>
                  :
                  <Row>
                    <Col><h3 className='text-danger '> امراض</h3></Col>
                    <Col><h3> لا يوجد امراض</h3></Col>
                  </Row>
                }
              </> :
              <Row>
                <Col><h3 className='text-danger '> الزوج/ة</h3></Col>
                <Col><h3> متوفي/ة</h3></Col>
              </Row>
          }
          </>:
              <Row>
                <Col><h3 className='text-dark '>اعزب / عزباء</h3></Col> 
              </Row>
          
          } 
        </Container>
        {/* spouse  */}

        {/* Father */}
        <Container className='mt-5'>
          <Row className="justify-content-md-center">
            <Col md="auto"><h2 className='text-dark'>معلومات عن الاب</h2></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> اسم الاب</h3></Col>
            <Col><h3>{fatherInfo && fatherInfo.firstname}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> شهرة الاب</h3></Col>
            <Col><h3>{fatherInfo && fatherInfo.lastname}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> تاريخ ولادة الاب</h3></Col>
            <Col><h3>{fatherInfo && fatherInfo.birthdate}</h3></Col>
          </Row>

          {
            fatherInfo && fatherInfo.isalive ?
              <>
                {fatherDaman.daman ?
                  <>
                    <Row>
                      <Col><h3 className='text-danger '> الضمان </h3></Col>
                      <Col><h3>{fatherDaman && fatherDaman.daman}</h3></Col>
                    </Row>
                  </>
                  :
                  <Row>
                    <Col><h3 className='text-danger '> ضمان </h3></Col>
                    <Col><h3> لا يوجد  </h3></Col>
                  </Row>
                }

                {fatherSicknesses && fatherSicknesses.hasSickness ?
                  <>
                    {fatherSicknesses && fatherSicknesses.fatherSicknesses.map((fatherSicknes, i) => (
                      <Row>
                        <Col><h3 className='text-danger '> المرض{i + 1}  </h3></Col>
                        <Col><h3> {fatherSicknes}  </h3></Col>
                      </Row>
                    ))
                    }
                  </>
                  :
                  <Row>
                    <Col><h3 className='text-danger '> امراض</h3></Col>
                    <Col><h3> لا يوجد امراض</h3></Col>
                  </Row>
                }
              </> :
              <Row>
                <Col><h3 className='text-danger '> الاب</h3></Col>
                <Col><h3> متوفي</h3></Col>
              </Row>
          }
        </Container>
        {/* father  */}


        {/* mother  */}
        <Container className='mt-5'>
          <Row className="justify-content-md-center">
            <Col md="auto"><h2 className='text-dark'>معلومات عن الام</h2></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> اسم الام</h3></Col>
            <Col><h3>{motherInfo && motherInfo.firstname}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> شهرة الام</h3></Col>
            <Col><h3>{motherInfo && motherInfo.lastname}</h3></Col>
          </Row>
          <Row>
            <Col><h3 className='text-danger'> تاريخ ولادة الام</h3></Col>
            <Col><h3>{motherInfo && motherInfo.birthdate}</h3></Col>
          </Row>

          {
            motherInfo && motherInfo.isalive ?
              <>
                {motherDaman.daman ?
                  <>
                    {/* <Row>
                      <Col><h3 className='text-danger '> الرقم المالي </h3></Col>
                      <Col><h3>{motherDaman && motherDaman.nofi}</h3></Col>
                    </Row> */}
                    <Row>
                      <Col><h3 className='text-danger '> الضمان </h3></Col>
                      <Col><h3>{motherDaman && motherDaman.daman}</h3></Col>
                    </Row>
                  </>
                  :
                  <Row>
                    <Col><h3 className='text-danger '> ضمان </h3></Col>
                    <Col><h3> لا يوجد  </h3></Col>
                  </Row>
                }

                {motherSicknesses && motherSicknesses.hasSickness ?
                  <>
                    {motherSicknesses && motherSicknesses.motherSicknesses.map((motherSicknes, i) => (
                      <Row>
                        <Col><h3 className='text-danger '> المرض{i + 1}  </h3></Col>
                        <Col><h3> {motherSicknes}  </h3></Col>
                      </Row>
                    ))
                    }
                  </>
                  :
                  <Row>
                    <Col><h3 className='text-danger '> امراض</h3></Col>
                    <Col><h3> لا يوجد امراض</h3></Col>
                  </Row>
                }
              </> :
              <Row>
                <Col><h3 className='text-danger '> الام</h3></Col>
                <Col><h3> متوفية</h3></Col>
              </Row>
          }
        </Container>
        {/* mother  */}

        <Container>
          <Row className="justify-content-md-center">
            <Col md="auto"><h5 className='text-dark'>اذا كانت جميع المعلومات  صحيحة انقر حفظ </h5></Col>
          </Row>
          <Row className="justify-content-md-center">
            <button type="button" className="btn btn-primary" onClick={() => saveData()}>
              حفظ
            </button>
          </Row>
        </Container>
      </div >

    </>
  );
};

export default SaveInfos;
