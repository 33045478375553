import React  from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PersonalInfoProvider } from './compoments/PersonalInfoContext';
import Home from './screens/HomeScreen';
import Person from './screens/PersonInfoScreen';
import Father from './screens/FatherScreen';
import Mother from './screens/MotherScreen';
import Spouse from './screens/SpouseScreen';
import Childs from './screens/ChildrensScreen';
import SaveInfos from './screens/SaveInfoScreen';
import End from './screens/EndScreen';
import Export from './screens/Export';

function Rout() { 
  return (
    <PersonalInfoProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Person" element={<Person />} /> 
              <Route path="/Father" element={<Father />} />
              <Route path="/Mother" element={<Mother />} />
              <Route path="/Spouse" element={<Spouse />} />
              <Route path="/Childs" element={<Childs />} />
              <Route path="/Save" element={<SaveInfos />} />  
              <Route path="/End" element={<End />} />  
              <Route path="/exportexcel" element={<Export />} />  
        </Routes>
      </Router>
    </PersonalInfoProvider>
  );
}

export default Rout;
