export const lebaneseGovernorates = [
  { value: 1, label: 'بيروت' },
  { value: 2, label: 'جبل لبنان' },
  { value: 3, label: 'كسروان جبيل' },
  { value: 4, label: 'البقاع' },
  { value: 5, label: 'بعلبك الهرمل' },
  { value: 6, label: 'الشمال' },
  { value: 7, label: 'عكار' },
  { value: 8, label: 'الجنوب' },
  { value: 9, label: 'النبطية' },
];      
export const lebaneseDistricts = [
  { value:1 ,id: 1, label: 'بيروت'},

  { value:2 ,id: 2, label: 'المتن' },
  { value:3 ,id: 2, label: 'بعبدا'},
  { value:4 ,id: 2, label: 'عاليه' },
  { value:5 ,id: 2, label: 'الشوف' },

  { value:6 ,id: 3, label: 'جبيل' },
  { value:7 ,id: 3, label: 'كسروان' },

  { value:8 ,id: 4, label: 'زحلة' },
  { value:9 ,id: 4, label: 'البقاع الغربي' },
  { value:10 ,id: 4, label: 'رشيا' },

  { value:11 ,id: 5, label: 'الهرمل' },
  { value:12 ,id: 5, label: 'بعلبك' },

  { value:13 ,id: 6, label: 'طرابلس' },
  { value:14 ,id: 6, label: 'زغرتا - الزاوية' },
  { value:15 ,id: 6, label: 'بشري' },
  { value:16 ,id: 6, label: 'البترون' },
  { value:17 ,id: 6, label: 'الكورة' },
  { value:18 ,id: 6, label: 'الضنية' },

  { value:19 ,id: 7, label: 'عكار' },

  { value:20 ,id: 8, label: 'صيدا' },
  { value:21 ,id: 8, label: 'صور' },
  { value:22 ,id: 8, label: 'جزين' },

  { value:23 ,id: 9, label: 'النبطية' },
  { value:24 ,id: 9, label: 'حاصبيا' },
  { value:25 ,id: 9, label: 'مرجعيون' },
  { value:26 ,id: 9, label: 'بنت جبيل' }, 
];

export const educationalLevels = [
  { value: 1, label: 'ابتدائي ' },
  { value: 2, label: 'متوسط' },
  { value: 3, label: 'متوسط رسمي' },
  { value: 4, label: 'ثانوي' },
  { value: 5, label: 'ثانوي رسمي' },
  { value: 6, label: 'اجازة' }, 
  { value: 7, label: 'الماجستير ' },
  { value: 8, label: 'الدكتوراه ' },
  { value: 9, label: 'الدبلوم العالي' }, 
  { value: 10, label: 'BP' }, 
  { value: 11, label: 'BT' }, 
  { value: 12, label: 'TS' }, 
  { value: 13, label: 'LT' }, 
];     
export const listcarcode = [
  { value: 1, label: 'بدون رمز '},
  { value: 2, label: 'A' },
  { value: 3, label: 'B - ب' },
  { value: 4, label: 'G - ج' },
  { value: 5, label: 'R - ر' }, 
  { value: 6, label: 'Z - ز' },
  { value: 7, label: 'S - ص' },
  { value: 8, label: 'T - ط' }, 
  { value: 9, label: 'J - ع' }, 
  { value: 10, label: 'P' }, 
  { value: 11, label: 'M - م' }, 
  { value: 12, label: 'N - ن' }, 
  { value: 13, label: 'O - و' }, 
  { value: 14, label: 'Y' }, 
  { value: 15, label: 'C - ق' }, 
];     
export const degrees = [
  { value: 1, label: 'نقيب' },
  { value: 2, label: 'معاون اول' },
  { value: 3, label: 'معاون اول مهني' },
  { value: 4, label: 'معاون' },
  { value: 5, label: 'معاون مهني' },
  { value: 6, label: 'رقيب اول' },
  { value: 7, label: 'رقيب اول مهني' },
  { value: 8, label: 'رقيب' }, 
  { value: 9, label: 'رقيب مهني' }, 
  { value: 10, label: 'اطفائي' },
  { value: 11, label: 'اطفائي مهني' },
  { value: 12, label: 'مسعف' }, 
  { value: 13, label: 'مسعف مهني' },  
];     
export const bloodType = [
  { value: 'A+', label: 'A+' },
  { value: 'A-', label: 'A-' },
  { value: 'B+', label: 'B+' },
  { value: 'B-', label: 'B-' },
  { value: 'AB+', label: 'AB+' },
  { value: 'AB-', label: 'AB-' },
  { value: 'O+', label: 'O+' },
  { value: 'O-', label: 'O-' }
];
