import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MSG } from '../compoments/url';

const ChildForm = ({ index, childInfo, setChildInfo }) => {




    const personalInfo = JSON.parse(localStorage.getItem('personInfo'));

    const nofi = personalInfo.nofi;
    const [name, setName] = useState(childInfo.name || '');
    const [lastName, setLastName] = useState(childInfo.lastName || '');
    const [birthdate, setBirthdate] = useState(childInfo.birthdate || '');

    const [inn, setInn] = useState(childInfo.inn || '');

    const [hasInss, setHasInss] = useState(childInfo.hasInss || false);
    const [hasFdpDaman, setHasFdpDaman] = useState(childInfo.hasFdpDaman || false);

    //no Daman
    const noDaman = () => {
        setHasInss(false);
        setHasFdpDaman(false);
        setInn('');
    }
    //Has Daman
    const hasDaman = () => {
        setHasInss(true);
        setHasFdpDaman(false);
        setInn('');
    }
    //Has FDP Daman
    const haFdpDaman = () => {
        setHasInss(false);
        setHasFdpDaman(true);
        setInn('بلدية بيروت');
    }
    const [hasSickness, setHasSickness] = useState(childInfo.hasSickness || false);
    const [sicknessCount, setSicknessCount] = useState(childInfo.sicknessCount || '');
    const [sicknessess, setSicknessess] = useState(childInfo.sicknessess || '');

    //no Sickness
    const noSickness = () => {
        setHasSickness(false);
        setSicknessCount('');
        setSicknessess('');
    }
    //Has Sickness
    const haSickness = () => {
        setHasSickness(true);
        // setSicknessCount('1');   
    }

    // Function to update sicknesses array
    const handleSicknessChange = (index, value) => {
        const updatedSicknesses = [...sicknessess];
        updatedSicknesses[index] = value;
        setSicknessess(updatedSicknesses);
    };

    // Function to handle sickness count change
    const handleSicknessCountChange = (e) => {
        const count = parseInt(e.target.value, 10);
        setSicknessCount(count);
        if (count > sicknessess.length) {
            setSicknessess([...sicknessess, '']);
        } else {
            setSicknessess(sicknessess.slice(0, count));
        }
    };
    // Render input fields based on sicknessCount
    const sicknessInputs =
        sicknessess && sicknessess.length > 0
            ?
            [...Array(sicknessCount)].map((_, index) => (
                <div className="input-group flex-nowrap mt-2 mb-2" key={index}>
                    <span className="input-group-text">نوع المرض {index + 1}</span>
                    <input
                        type="text"
                        className="form-control"
                        value={sicknessess[index] || ''}
                        onChange={(e) => handleSicknessChange(index, e.target.value)}
                        required // Add the required attribute
                    />
                </div>
            ))
            : null;

    useEffect(() => {
        setChildInfo((childInfos) => {
            const updatedChildInfos = [...childInfos];
            updatedChildInfos[index] = {
                nofi,
                name,
                lastName,
                birthdate,
                inn,
                hasInss,
                hasFdpDaman,
                hasSickness,
                sicknessCount,
                sicknessess
            };
            return updatedChildInfos;
        });
    }, [nofi, name, lastName, birthdate, inn, hasInss, hasFdpDaman, sicknessess, hasSickness, sicknessCount, index, setChildInfo]);

    return (
        <div>
            <h1>معلومات عن الولد {index + 1}</h1>
            <div className="input-group flex-nowrap mt-2 mb-2">
                <span className="input-group-text">الاسم</span>
                <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div className="input-group flex-nowrap mt-2 mb-2">
                <span className="input-group-text">الشهرة</span>
                <input
                    type="text"
                    className="form-control"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </div>
            <div className="input-group flex-nowrap mt-2 mb-2">
                <span className="input-group-text">تاريخ الميلاد</span>
                <input
                    type="date"
                    className="form-control"
                    value={birthdate}
                    onChange={(e) => setBirthdate(e.target.value)}
                    onClick={() => {
                        if (!birthdate) {
                            setBirthdate('1990-01-01');
                        }
                    }}
                />
            </div>

            <div className="input-group flex-nowrap mt-2 mb-2">
                <span className="input-group-text">الضمان</span>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={`inlineRadioOptionsInn${index + 1}`}
                        onChange={() => haFdpDaman()}
                        checked={!hasInss && hasFdpDaman && 'checked'}
                        id={`hasFDPInss${index + 1}`}
                    />
                    <label className="form-check-label" htmlFor={`hasFDPInss${index + 1}`}>
                        مضمون لدى بلدية بيروت
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={`inlineRadioOptionsInn${index + 1}`}
                        onChange={() => hasDaman()}
                        checked={hasInss && !hasFdpDaman && 'checked'}
                        id={`hasInss${index + 1}`}
                    />
                    <label className="form-check-label" htmlFor={`hasInss${index + 1}`}>
                        تحديد الجهة الضامنة
                    </label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={`inlineRadioOptionsInn${index + 1}`}
                        onChange={() => noDaman()}
                        checked={!hasInss && !hasFdpDaman && 'checked'}
                        id={`noInn${index + 1}`}
                    />
                    <label className="form-check-label" htmlFor={`noInn${index + 1}`}>
                        لا يوجد
                    </label>
                </div>
            </div>

            {hasInss &&
                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">الجهة الضامنة</span>
                    <input
                        type="text"
                        className="form-control"
                        value={inn} onChange={(e) => setInn(e.target.value)}
                    />
                </div>
            }


            <div className="input-group flex-nowrap mt-2 mb-2">
                <span className="input-group-text">امراض مزمنة</span>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={`inlineRadioOptions2${index + 1}`}
                        onChange={() => noSickness()}
                        checked={hasSickness ? '' : 'checked'}
                        id={`nosick${index + 1}`}
                    />
                    <label className="form-check-label" htmlFor={`nosick${index + 1}`}>لا يوجد</label>
                </div>
                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="radio"
                        name={`inlineRadioOptions2${index + 1}`}
                        onChange={() => haSickness()}
                        checked={hasSickness ? 'checked' : ''}
                        id={`hassick${index + 1}`}
                    />
                    <label className="form-check-label" htmlFor={`hassick${index + 1}`}>تحديد الامراض</label>
                </div>
            </div>

            {hasSickness &&
                <>
                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text">عدد الأمراض</span>
                        <input
                            min='1'
                            type="number"  pattern="[0-9]*" inputMode="numeric" 
                            className="form-control"
                            value={sicknessCount || ''}
                            onChange={handleSicknessCountChange}
                        />
                    </div>
                    {sicknessInputs && sicknessCount !== '' && sicknessInputs}
                </>
            }
        </div>
    );
};

const ChildsForm = () => {

    const personalInfo = JSON.parse(localStorage.getItem('personInfo'));
    const spouseInfo = JSON.parse(localStorage.getItem('spouseInfo'));
    const [numberOfChildren, setNumberOfChildren] = useState(0);
    const [childInfo, setChildInfo] = useState([]);
    const navigate = useNavigate();

    const [checkFields, setCheckFields] = useState(false);

    useEffect(() => {
        const storedChildInfo = JSON.parse(localStorage.getItem('childInfo'));
        if (storedChildInfo && storedChildInfo.length > 0) {
            setChildInfo(storedChildInfo);
            setNumberOfChildren(storedChildInfo.length);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('childInfo', JSON.stringify(childInfo));
    }, [childInfo]);

    // useEffect(() => {
    //     if (!personalInfo) {
    //           navigate('/Person');
    //     }
    // }, [personalInfo, navigate]);


    useEffect(() => {
        if (!personalInfo || personalInfo.married && !spouseInfo) {
            navigate('/Person');
        }
    }, [personalInfo, navigate, spouseInfo]);

    const handleNumberOfChildren = (e) => {
        let count = parseInt(e.target.value);
        if (isNaN(count) || count < 0) {
            count = 0;
        } else if (count > 30) {
            count = 30;
        }
        setNumberOfChildren(count);
        setChildInfo(Array(count).fill({}));
    };

    const noChilds = () => {
        setNumberOfChildren(0)
        setChildInfo([])
        localStorage.removeItem('childInfo');
        navigate('/Father')
    }

    const confirm = () => {
        for (let i = 0; i < numberOfChildren; i++) {
            if (
                childInfo[i].name === ''
                || childInfo[i].lastName === ''
                || childInfo[i].birthdate === ''
                || (childInfo[i].hasInss && childInfo[i].inn === '')
                || (childInfo[i].hasSickness && childInfo[i].sicknessess.some((sickness) => sickness === ''))

            ) {
                alert(`يرج ملء جميع الخانات الولد رقم  ${i + 1}`)
                return false
            } else {
                setCheckFields(true);
            }

        }

        navigate('/Father')
    }
    return (
        <div className="container d-flex align-items-center justify-content-center min-vh-100" dir="rtl">

            <div className="text-center">
                {!checkFields &&
        <div dangerouslySetInnerHTML={{ __html: MSG }} className='text-danger fs-1 fw-bold' />
                }
                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">عدد الاولاد</span>
                    <input
                        type="number"  pattern="[0-9]*" inputMode="numeric" 
                        className="form-control"
                        value={numberOfChildren || ''}
                        onChange={handleNumberOfChildren}
                        max={30}
                    />

                </div>
                {childInfo.map((child, index) => (
                    <ChildForm
                        key={index}
                        index={index}
                        childInfo={child}
                        setChildInfo={setChildInfo}
                    />
                ))}
                <div className='d-flex'>
                    <button className="btn btn-warning m-4" onClick={() => { personalInfo.married ? navigate('/Spouse') : navigate('/Person') }}>السابق</button>
                    <button className="btn btn-primary m-4" onClick={() => noChilds()}>لا يوجد اولاد</button>
                    {numberOfChildren > 0 && <button className="btn btn-primary m-4" onClick={() => confirm()} >التالي</button>}
                </div>

            </div>
        </div>
    );
};

export default ChildsForm;
