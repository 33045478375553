import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { PersonalInfoContext } from '../compoments/PersonalInfoContext';
import { MSG } from '../compoments/url';

const Mother = () => {

    const navigate = useNavigate();

    const personalInfo = JSON.parse(localStorage.getItem('personInfo'));
    const spouseInfo = JSON.parse(localStorage.getItem('spouseInfo')); 
    const fatherInfo = JSON.parse(localStorage.getItem('fatherInfo'));

    const motherInfo = JSON.parse(localStorage.getItem('motherInfo'));
    const motherDaman = JSON.parse(localStorage.getItem('motherDaman'));
    const motherSicknesses = JSON.parse(localStorage.getItem('motherSicknesses'));

    const [name, setName] = useState(motherInfo ? motherInfo.firstname : '');
    const [lastName, setLastname] = useState(motherInfo ? motherInfo.lastname : '');
    const [birthdate, setBirthdate] = useState(motherInfo ? motherInfo.birthdate : '');
    const [isAlive, setIsAlive] = useState(motherInfo ? motherInfo.isalive : true);

    const [inn, setInn] = useState(motherDaman ? motherDaman.daman : '');

    const [hasInss, setHasInss] = useState(motherDaman ? motherDaman.hasInss : false);
    const [hasFdpDaman, setHasFdpDaman] = useState(motherDaman ? motherDaman.hasFdpDaman : false);

    const [hasSickness, setHasSickness] = useState(motherSicknesses ? motherSicknesses.hasSickness : false);
    const [sicknessCount, setSicknessCount] = useState(motherSicknesses ? motherSicknesses.sicknessCount : '');
    const [sicknessess, setSicknessess] = useState(motherSicknesses ? motherSicknesses.motherSicknesses : '');

    const [checkFields, setCheckFields] = useState(false);

    //no Daman
    const noDaman = () => {
        setHasInss(false);
        setHasFdpDaman(false);
        setInn('');
    }
    //Has Daman
    const hasDaman = () => {
        setHasInss(true);
        setHasFdpDaman(false);
        setInn('');
    }
    //Has FDP Daman
    const haFdpDaman = () => {
        setHasInss(false);
        setHasFdpDaman(true);
        setInn('بلدية بيروت');
    }

    //no Sickness
    const noSickness = () => {
        setHasSickness(false);
        setSicknessCount('');
        setSicknessess('');
    }
    //Has Sickness
    const haSickness = () => {
        setHasSickness(true);
        // setSicknessCount('1');   
    }

    // Function to update sicknesses array
    const handleSicknessChange = (index, value) => {
        const updatedSicknesses = [...sicknessess];
        updatedSicknesses[index] = value;
        setSicknessess(updatedSicknesses);
    };

    // Function to handle sickness count change
    const handleSicknessCountChange = (e) => {
        const count = parseInt(e.target.value, 10);
        setSicknessCount(count);
        if (count > sicknessess.length) {
            setSicknessess([...sicknessess, '']);
        } else {
            setSicknessess(sicknessess.slice(0, count));
        }
    };

    // Render input fields based on sicknessCount
    const sicknessInputs =
        sicknessess && sicknessess.length > 0
            ?
            [...Array(sicknessCount)].map((_, index) => (
                <div className="input-group flex-nowrap mt-2 mb-2" key={index}>
                    <span className="input-group-text">نوع المرض {index + 1}</span>
                    <input
                        type="text"
                        className="form-control"
                        value={sicknessess[index] || ''}
                        onChange={(e) => handleSicknessChange(index, e.target.value)}
                        required // Add the required attribute
                    />
                </div>
            ))
            : null;

    const handleConfirm = () => {
        if (
            name === ''
            || lastName === ''
            || birthdate === ''
            || isAlive === ''
            || (hasInss && inn === '')
            || (hasSickness && sicknessess.some((sickness) => sickness === ''))
        ) {
            alert(MSG)
            return false
        } else {
            setCheckFields(true);

            const motherInfo = {
                nofi: personalInfo.nofi,
                firstname: name,
                lastname: lastName,
                birthdate: birthdate,
                isalive: isAlive,
            }


            const motherDaman = {
                nofi: personalInfo.nofi,
                daman: inn,
                hasFdpDaman: hasFdpDaman,
                hasInss: hasInss,
            }

            const fSicknesses = {
                nofi: personalInfo.nofi,
                hasSickness: hasSickness,
                sicknessCount: sicknessCount,
                motherSicknesses: sicknessess,

            }

            localStorage.setItem('motherInfo', JSON.stringify(motherInfo));
            localStorage.setItem('motherDaman', JSON.stringify(motherDaman));
            localStorage.setItem('motherSicknesses', JSON.stringify(fSicknesses));

            navigate('/Save');
        }
    };
    
    useEffect(() => {
        if (!personalInfo || personalInfo.married && !spouseInfo || !fatherInfo ) {
              navigate('/Person');
        }

    }, [personalInfo, navigate, spouseInfo, fatherInfo]);

    return (
        <div className="container d-flex align-items-center justify-content-center min-vh-100" dir="rtl">
            <div className="text-center">
                <h1>معلومات عن الام</h1>
                {!checkFields &&
        <div dangerouslySetInnerHTML={{ __html: MSG }} className='text-danger fs-1 fw-bold' />
                }
                <div className="  flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">الرقم المالي {personalInfo && personalInfo.nofi}  </span>

                </div>

                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">الاسم</span>
                    <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                </div>

                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">الشهرة</span>
                    <input type="text" className="form-control" value={lastName} onChange={(e) => setLastname(e.target.value)} />
                </div>

                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">تاريخ الميلاد</span>
                    <input type="date" className="form-control" value={birthdate} 
                            onClick={() => {
                              if (!birthdate) {
                                setBirthdate('1990-01-01');
                              }
                            }} onChange={(e) => setBirthdate(e.target.value)} />
                </div>


                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">هل هي على قيد الحياة ؟</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            onChange={() => setIsAlive(true)}
                            checked={isAlive && 'checked'}
                            id='alive'
                        />
                        <label className="form-check-label" htmlFor="alive">نعم </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            onChange={() => setIsAlive(false)}
                            checked={!isAlive && 'checked' }
                            id='dead'
                        />
                        <label className="form-check-label" htmlFor="dead">لا</label>
                    </div>
                </div>


                {isAlive &&
                <>
                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">الضمان</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptionsInn"
                            onChange={() => haFdpDaman()}
                            checked={!hasInss && hasFdpDaman && 'checked'}
                            id='hasFdpInss'
                        />
                        <label className="form-check-label" htmlFor="hasFdpInss">مضمون لدى بلدية بيروت</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptionsInn"
                            onChange={() => hasDaman()}
                            checked={hasInss && !hasFdpDaman && 'checked'}
                            id='hasInss'
                        />
                        <label className="form-check-label" htmlFor="hasInss">تحديد جهة ضامنة اخرى</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptionsInn"
                            onChange={() => noDaman()}
                            checked={!hasInss && !hasFdpDaman && 'checked'}
                            id='noInn'
                        />
                        <label className="form-check-label" htmlFor="noInn">لا يوجد</label>
                    </div>
                </div>

                {hasInss &&
                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text">الجهة الضامنة</span>
                        <input
                            type="text"
                            className="form-control"
                            value={inn}
                            onChange={(e) => setInn(e.target.value)}
                        />
                    </div>
                }

                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">امراض مزمنة</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions2"
                            onChange={() => haSickness()}
                            checked={hasSickness ? 'checked' : ''}
                            id='hassick'
                        />
                        <label className="form-check-label" htmlFor="hassick">تحديد الامراض</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions2"
                            onChange={() => noSickness()}
                            checked={hasSickness ? '' : 'checked'}
                            id='nosick'
                        />
                        <label className="form-check-label" htmlFor="nosick">لا يوجد</label>
                    </div>
                </div>

                {hasSickness &&
                    <>
                        <div className="input-group flex-nowrap mt-2 mb-2">
                            <span className="input-group-text">عدد الأمراض</span>
                            <input
                                min='1'
                                type="number"  pattern="[0-9]*" inputMode="numeric" 
                                className="form-control"
                                value={sicknessCount || ''}
                                onChange={handleSicknessCountChange}
                            />
                        </div>
                        {sicknessInputs && sicknessCount !== '' && sicknessInputs}
                    </>
                }
                </>
                }
                <div>
                    <button type="button" className="btn btn-warning  w-25 m-3" onClick={() => navigate('/Father')}>
                        السابق
                    </button>
                    <button type="button" className="btn btn-primary  w-25" onClick={handleConfirm}>
                        التالي
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Mother;
