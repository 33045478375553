import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MSG } from '../compoments/url';

const Home = () => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate('/Person');
  };

  return (
    <div className="container  d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1 className="text-center">فوج اطفاء مدينة بيروت</h1>
        <h1 className="text-center m-5">معلومات شخصية وصحية</h1>
        <div dangerouslySetInnerHTML={{ __html: MSG }} className='text-danger fs-1 fw-bold' />

        <button type="button" className="btn btn-primary btn-lg w-50 m-5" onClick={handleConfirm}>
          ابدء
        </button>
      </div>
    </div>
  );
};

export default Home;
