import { URLAPI } from "../compoments/url.js";
import axios from 'axios';

export const setPersonInfo = (personInfo) => {
  
  const data_auth = { action: 'setPersonInfo', personInfo: personInfo};
  axios.post(URLAPI, data_auth, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    // Handle the response if needed
    console.log(response.message);
  })
  .catch(error => {
    // Handle any error that occurs during the request
    console.error(error);
  });
};

export const setCarInfo = (carInfo) => {
  
  const data_auth = { action: 'setCarInfo', carInfo:carInfo};
  axios.post(URLAPI, data_auth, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    // Handle the response if needed
    console.log(response.message);
  })
  .catch(error => {
    // Handle any error that occurs during the request
    console.error(error);
  });
};

export const setpSicknesses = (pSicknesses) => {
  
  const data_auth = { action: 'setpSicknesses', pSicknesses:pSicknesses};
  axios.post(URLAPI, data_auth, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    // Handle the response if needed
    console.log(response.message);
  })
  .catch(error => {
    // Handle any error that occurs during the request
    console.error(error);
  });
};

export const setChildsInfo = (childsInfo) => {
  
  const data_auth = { action: 'setChildsInfo', childsInfo:childsInfo};
  axios.post(URLAPI, data_auth, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    // Handle the response if needed
    console.log(response.message);
  })
  .catch(error => {
    // Handle any error that occurs during the request
    console.error(error);
  });
};

export const setSpouseInfo = (spouseInfo, spouseDaman, spouseSicknesses) => {
  
  const data_auth = { action: 'setSpouseInfo', spouseInfo:spouseInfo, spouseDaman:spouseDaman, spouseSicknesses:spouseSicknesses};
  axios.post(URLAPI, data_auth, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    // Handle the response if needed
    console.log(response.message);
  })
  .catch(error => {
    // Handle any error that occurs during the request
    console.error(error);
  });
};
 
export const setFatherInfo = (fatherInfo, fatherDaman, fatherSicknesses) => {
  
  const data_auth = { action: 'setFatherInfo', fatherInfo:fatherInfo, fatherDaman:fatherDaman, fatherSicknesses:fatherSicknesses};
  axios.post(URLAPI, data_auth, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    // Handle the response if needed
    console.log(response.message);
  })
  .catch(error => {
    // Handle any error that occurs during the request
    console.error(error);
  });
};
  
export const setMotherInfo = (motherInfo, motherDaman, motherSicknesses) => {
  
  const data_auth = { action: 'setMotherInfo', motherInfo:motherInfo, motherDaman:motherDaman, motherSicknesses:motherSicknesses};
  axios.post(URLAPI, data_auth, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    // Handle the response if needed
    console.log(response.message);
  })
  .catch(error => {
    // Handle any error that occurs during the request
    console.error(error);
  });
};
 
  
 
   //GetData
   export  const GetData = ({setData}) => {
    const data_auth = { action: 'getData' };
    axios.post(URLAPI, data_auth, {
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((responseJson) => {
      const serverData = { ...responseJson };
      if (!serverData.error) {
        const dataString = serverData.data;
        setData(dataString.data);
      }
      else {
        alert(serverData.message);
      }
    })
    .catch((error) => {
      // alert(error);
    });
  };
  /// End GetData
 
 