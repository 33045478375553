import React from 'react'; 

const End = () => { 

  return (
    <div className="container  d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <h1>فوج اطفاء مدينة بيروت</h1> 
      </div>
    </div>
  );
};

export default End;
