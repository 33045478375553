import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    lebaneseGovernorates,
    lebaneseDistricts,
    educationalLevels,
    listcarcode,
    degrees,
    bloodType
} from '../compoments/selectOptions';
import Select from 'react-select';
import { MSG } from '../compoments/url';


const Person = () => {

    const navigate = useNavigate();

    const personalInfo = JSON.parse(localStorage.getItem('personInfo'));
    const carInfo = JSON.parse(localStorage.getItem('carInfo'));
    const personSicknessess = JSON.parse(localStorage.getItem('personSicknesses'));

    const [nofi, setNofi] = useState(personalInfo ? personalInfo.nofi : '');
    const [degree, setDegree] = useState(personalInfo ? personalInfo.degree : '');

    const [name, setName] = useState(personalInfo ? personalInfo.firstname : '');
    const [lastName, setLastname] = useState(personalInfo ? personalInfo.lastname : '');
    const [phoneNumber, setPhoneNumber] = useState(personalInfo ? personalInfo.phonenumber : '');
    const [blood, setBlood] = useState(personalInfo ? personalInfo.blood : '');
    const [birthdate, setBirthdate] = useState(personalInfo ? personalInfo.birthdate : '');
    const [birthplace, setBirthplace] = useState(personalInfo ? personalInfo.birthplace : '');
    const [educationalLevel, setEducationalLevel] = useState(personalInfo ? personalInfo.educationalLevel : '');
    const [married, setMarried] = useState(personalInfo ? personalInfo.married : false);

    const [governorate, setGovernorate] = useState(personalInfo ? personalInfo.governorate : '');
    const [district, setDistrict] = useState(personalInfo ? personalInfo.district : '');
    const [recordPlace, setRecordPlace] = useState(personalInfo ? personalInfo.recordPlace : '');
    const [recordNumber, setRecordNumber] = useState(personalInfo ? personalInfo.recordNumber : '');

    const [city, setCity] = useState(personalInfo ? personalInfo.city : '');
    const [region, setRegion] = useState(personalInfo ? personalInfo.region : '');
    const [street, setStreet] = useState(personalInfo ? personalInfo.street : '');
    const [building, setBuilding] = useState(personalInfo ? personalInfo.building : '');
    const [floor, setFloor] = useState(personalInfo ? personalInfo.floor : '');

    const [hasCar, setHasCar] = useState(carInfo ? carInfo.hasCar : false)
    const [carBrand, setCarBrand] = useState(carInfo ? carInfo.carBrand : '')
    const [carCode, setCarCode] = useState(carInfo ? carInfo.carCode : '')
    const [carNumber, setCarNumber] = useState(carInfo ? carInfo.carNumber : '')

    const [hasSickness, setHasSickness] = useState(personSicknessess ? personSicknessess.hasSickness : false);
    const [sicknessCount, setSicknessCount] = useState(personSicknessess ? personSicknessess.sicknessCount : '');
    const [personSicknesses, setPersonSicknesses] = useState(personSicknessess ? personSicknessess.personSicknesses : '');

    // Replace Arabic numbers with English numbers
    const englishNumbers = {
        '٠': '0',
        '١': '1',
        '٢': '2',
        '٣': '3',
        '٤': '4',
        '٥': '5',
        '٦': '6',
        '٧': '7',
        '٨': '8',
        '٩': '9',
    };

    //no Car
    const noCar = () => {
        setHasCar(false);
        setCarBrand('');
        setCarCode('');
        setCarNumber('');
    }
    //Has Car
    const haCar = () => {
        setHasCar(true);
    }
    //no Sickness
    const noSickness = () => {
        setHasSickness(false);
        setSicknessCount('');
        setPersonSicknesses('');
    }
    //Has Sickness
    const haSickness = () => {
        setHasSickness(true);
    }

    // Function to update sicknesses array
    const handleSicknessChange = (index, value) => {
        const updatedSicknesses = [...personSicknesses];
        updatedSicknesses[index] = value;
        setPersonSicknesses(updatedSicknesses);
    };

    // Function to handle sickness count change
    const handleSicknessCountChange = (e) => {


        const inputValue = e.target.value;

        let sanitizedValue = '';
        for (let i = 0; i < inputValue.length; i++) {
            const char = inputValue[i];
            sanitizedValue += englishNumbers[char] || char;
        }

        const isNonEnglishInput = /[^\u0000-\u007F]/.test(inputValue);
        if (isNonEnglishInput) {
            // Display an error message or perform any other necessary action
            return;
        }


        const count = parseInt(e.target.value, 10);
        setSicknessCount(count);
        if (count > personSicknesses.length) {
            setPersonSicknesses([...personSicknesses, '']);
        } else {
            setPersonSicknesses(personSicknesses.slice(0, count));
        }
    };

    // Render input fields based on sicknessCount
    const sicknessInputs =
        personSicknesses && personSicknesses.length > 0
            ?
            [...Array(sicknessCount)].map((_, index) => (
                <div className="input-group flex-nowrap mt-2 mb-2" key={index}>
                    <span className="input-group-text">نوع المرض {index + 1}</span>
                    <input
                        type="text"
                        className="form-control"
                        value={personSicknesses[index] || ''}
                        onChange={(e) => handleSicknessChange(index, e.target.value)}
                        required // Add the required attribute
                    />
                </div>
            ))
            : null;

    const [checkFields, setCheckFields] = useState(false);

    const handleConfirm = () => {
        if (
            nofi === ''
            || degree === ''

            || name === ''
            || lastName === ''
            || phoneNumber === ''
            || blood === ''
            || birthdate === ''
            || birthplace === ''
            || educationalLevel === ''

            || governorate === ''
            || district === ''
            || recordPlace === ''
            || recordNumber === ''

            || city === ''
            || region === ''
            || street === ''
            || building === ''
            || floor === ''

            || (hasCar && carBrand === '')
            || (hasCar && carCode === '')
            || (hasCar && carNumber === '')

            || (hasSickness && personSicknesses.some((sickness) => sickness === ''))

        ) {
            alert(MSG)
            return false
        } else {
            setCheckFields(true)
            const personInfo = {
                nofi: nofi,
                degree: degree,

                firstname: name,
                lastname: lastName,
                phonenumber: phoneNumber,
                blood: blood,
                birthdate: birthdate,
                birthplace: birthplace,
                educationalLevel: educationalLevel,
                married: married,

                governorate: governorate,
                district: district,
                recordPlace: recordPlace,
                recordNumber: recordNumber,

                city: city,
                region: region,
                street: street,
                building: building,
                floor: floor,
            };
            const carInfo = {
                nofi: nofi,
                hasCar: hasCar,
                carBrand: carBrand,
                carCode: carCode,
                carNumber: carNumber,
            }
            const pSicknesses = {
                nofi: nofi,
                hasSickness: hasSickness,
                sicknessCount: sicknessCount,
                personSicknesses: personSicknesses,
            }
            localStorage.setItem('personInfo', JSON.stringify(personInfo));
            localStorage.setItem('carInfo', JSON.stringify(carInfo));
            localStorage.setItem('personSicknesses', JSON.stringify(pSicknesses));

            if (married) {
                navigate('/Spouse');
            } else {
                navigate('/Childs');
            }
        }
    };

    return (
        <div className="container d-flex align-items-center justify-content-center min-vh-100 bg-light" dir="rtl">
            <div className="text-center">
                <h1>معلومات شخصية</h1>
                {!checkFields &&
                    <div dangerouslySetInnerHTML={{ __html: MSG }} className='text-danger fs-1 fw-bold' />
                }
                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">الرقم المالي</span>
                    {/* <input type="number"
                        lang="en"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        className="form-control"
                        value={nofi} onChange={(e) => setNofi(e.target.value)}
                    /> */}
                    <input
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        lang="en"
                        className="form-control"
                        value={nofi}
                        onChange={(e) => {
                            const englishNumber = e.target.value.replace(/[^0-9]/g, '');
                            setNofi(englishNumber);
                        }}
                    />
                </div>
                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">الرتبة</span>
                    <Select
                        options={degrees}
                        isSearchable={true}
                        onChange={setDegree}
                        value={degree ? degrees.find(option => option.value === degree.value) : ''}
                        className="w-100"
                    />
                </div>

                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="flex-nowrap mt-2 mb-2 ">  </span>
                </div>
                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">الاسم</span>
                    <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                </div>

                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">الشهرة</span>
                    <input type="text" className="form-control" value={lastName} onChange={(e) => setLastname(e.target.value)} />
                </div>

                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">رقم الهاتف</span>
                    {/* <input type="number" 
                    pattern="[0-9]*" 
                    inputMode="numeric" 
                    className="form-control" 
                    value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} 
                    />
                     */}
                    <input
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        lang="en"
                        className="form-control"
                        value={phoneNumber}
                        onChange={(e) => {
                            const englishNumber = e.target.value.replace(/[^0-9]/g, '');
                            setPhoneNumber(englishNumber);
                        }}
                    />
                </div>

                <div className="flex-nowrap mt-3 mb-2">
                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text">فئة الدم</span>
                        <Select
                            options={bloodType}
                            isSearchable={true}
                            onChange={setBlood}
                            value={blood ? bloodType.find(option => option.value === blood.value) : ''}
                            className="w-100"
                        />
                    </div>
                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text">مكان الولادة</span>
                        <input type="text" className="form-control" value={birthplace} onChange={(e) => setBirthplace(e.target.value)} />
                    </div>

                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text">تاريخ الميلاد </span>
                        <input type="date" className="form-control"
                            // value={!birthdate ? ('1990-02-01') : birthdate}
                            value={birthdate && birthdate}
                            onChange={(e) => setBirthdate(e.target.value)}
                            onClick={() => {
                                if (!birthdate) {
                                    setBirthdate('1990-01-01');
                                }
                            }}


                        />
                    </div>
                </div>

                <div className="input-group flex-nowrap mt-4 mb-2">
                    <span className="input-group-text"> المستوى العلمي</span>
                    <Select
                        options={educationalLevels}
                        isSearchable={true}
                        onChange={setEducationalLevel}
                        className="w-100"
                        value={educationalLevel && educationalLevels.find(option => option.value === educationalLevel.value)}

                    />
                </div>

                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">الوضع العائلي</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptionsmd"
                            onChange={() => setMarried(false)}
                            checked={!married && 'checked'}
                            id='singel'
                        />
                        <label className="form-check-label" htmlFor="singel">اعزب / عزباء</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptionsmd"
                            onChange={() => setMarried(true)}
                            checked={married && 'checked'}
                            id='married'
                        />
                        <label className="form-check-label" htmlFor="married">متاهل/ة</label>
                    </div>
                </div>

                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="flex-nowrap mt-2 mb-2 ">    </span>
                </div>
                <div className="flex-nowrap mt-3 mb-2">
                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text"> المحافظة</span>
                        <Select
                            options={lebaneseGovernorates}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                setGovernorate(selectedOption);
                                setDistrict(''); // Make sure to pass the necessary parameters to setDistrict if needed
                            }}
                            value={governorate && lebaneseGovernorates.find(option => option.value === governorate.value)}
                            className="w-100"
                        />
                    </div>

                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text"> القضاء</span>
                        <Select
                            options={lebaneseDistricts.filter(option => option.id === governorate.value)}
                            isSearchable={true}
                            // onChange={setDistrict}
                            onChange={(selectedOption) => {
                                setDistrict(selectedOption); 
                            }}
                            className="w-100"
                            value={district}
                        />
                    </div>

                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text">مكان السجل</span>
                        <input
                            type="text"
                            className="form-control"
                            value={recordPlace}
                            onChange={(e) => setRecordPlace(e.target.value)}
                        />
                    </div>

                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text">رقم السجل</span>
                        {/* <input
                            type="number" pattern="[0-9]*" inputMode="numeric"
                            className="form-control"
                            value={recordNumber}
                            onChange={(e) => setRecordNumber(e.target.value)}
                        /> */}
                        
                    <input
                        type="text"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        lang="en"
                        className="form-control"
                        value={recordNumber}
                        onChange={(e) => {
                            const englishNumber = e.target.value.replace(/[^0-9]/g, '');
                            setRecordNumber(englishNumber);
                        }}
                    />
                    </div>
                </div>

                <div className="flex-nowrap mt-3 mb-2">
                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="flex-nowrap mt-2 mb-2 "> السكن</span>
                    </div>

                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text">المدينة</span>
                        <input
                            type="text"
                            className="form-control"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </div>

                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text">المنطقة</span>
                        <input
                            type="text"
                            className="form-control"
                            value={region}
                            onChange={(e) => setRegion(e.target.value)}
                        />
                    </div>

                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text">شارع</span>
                        <input
                            type="text"
                            className="form-control"
                            value={street}
                            onChange={(e) => setStreet(e.target.value)}
                        />
                    </div>

                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text">بناية</span>
                        <input
                            type="text"
                            className="form-control"
                            value={building}
                            onChange={(e) => setBuilding(e.target.value)}
                        />
                    </div>

                    <div className="input-group flex-nowrap mt-2 mb-2">
                        <span className="input-group-text">طابق</span>
                        {/* <input
                            type="number" pattern="[0-9]*" inputMode="numeric"
                            className="form-control"
                            value={floor}
                            onChange={(e) => setFloor(e.target.value)}
                        /> */}
                        <input
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            lang="en"
                            className="form-control"
                            value={floor}
                            onChange={(e) => {
                                const englishNumber = e.target.value.replace(/[^0-9]/g, '');
                                setFloor(englishNumber);
                            }}
                        />
                    </div>
                </div>

                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">سيارة</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            onChange={() => noCar()}
                            checked={hasCar ? '' : 'checked'}
                            id='nocar'
                        />
                        <label className="form-check-label" htmlFor="nocar">لا يوجد</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions"
                            onChange={() => haCar()}
                            checked={hasCar ? 'checked' : ''}
                            id='hascar'
                        />
                        <label className="form-check-label" htmlFor="hascar">معلومات عن السيارة</label>
                    </div>
                </div>
                {hasCar &&
                    <>
                        <div className="input-group flex-nowrap mt-2 mb-2">
                            <span className="input-group-text">نوع السيارة</span>
                            <input
                                type="text"
                                className="form-control"
                                value={carBrand} onChange={(e) => setCarBrand(e.target.value)}
                            />
                        </div>
                        <div className="input-group flex-nowrap mt-2 mb-2">
                            <span className="input-group-text">رقم ورمز السيارة</span>
                            <Select
                                options={listcarcode}
                                isSearchable={false}
                                onChange={setCarCode}
                                value={carCode && listcarcode.find(option => option.value === carCode.value)}

                            />
                            {/* <input
                                type="number" pattern="[0-9]*" inputMode="numeric"
                                className="form-control"
                                value={carNumber} onChange={(e) => setCarNumber(e.target.value)}
                            /> */}
                            <input
                                type="text"
                                inputMode="numeric"
                                pattern="[0-9]*"
                                lang="en"
                                className="form-control"
                                value={carNumber}
                                onChange={(e) => {
                                    const englishNumber = e.target.value.replace(/[^0-9]/g, '');
                                    setCarNumber(englishNumber);
                                }}
                            />
                        </div>
                    </>
                }


                <div className="input-group flex-nowrap mt-2 mb-2">
                    <span className="input-group-text">امراض مزمنة</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions2"
                            onChange={() => noSickness()}
                            checked={hasSickness ? '' : 'checked'}
                            id='nosick'
                        />
                        <label className="form-check-label" htmlFor="nosick">لا يوجد</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions2"
                            onChange={() => haSickness()}
                            checked={hasSickness ? 'checked' : ''}
                            id='hassick'
                        />
                        <label className="form-check-label" htmlFor="hassick">تحديد الامراض</label>
                    </div>
                </div>

                {hasSickness &&
                    <>
                        <div className="input-group flex-nowrap mt-2 mb-2">
                            <span className="input-group-text">عدد الأمراض</span>
                            <input
                                min='1'
                                type="number" pattern="[0-9]*" inputMode="numeric"
                                className="form-control"
                                value={sicknessCount || ''}
                                onChange={handleSicknessCountChange}
                            />
                        </div>
                        {sicknessInputs && sicknessCount !== '' && sicknessInputs}
                    </>
                }

                <button type="button" className="btn btn-primary m-4 w-50" onClick={handleConfirm}>
                    التالي
                </button>
            </div>
        </div>
    );
};

export default Person;
