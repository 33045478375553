// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Rout from './router';

function App() {
  return (
   <Rout />
  );
}

export default App;
