import React, { createContext, useState } from 'react';

export const PersonalInfoContext = createContext();

export const PersonalInfoProvider = ({ children }) => {
  const [personalInfo, setPersonInfo] = useState([]);
  const [fatherInfo, setFatherInfo] = useState([]);
  const [motherInfo, setMotherInfo] = useState([]);
  const [spouseInfo, setSpouseInfo] = useState([]);
  const [childsInfo, setChildsInfo] = useState([]);

  return (
    <PersonalInfoContext.Provider value={{ 

      personalInfo, 
      setPersonInfo, 

      fatherInfo, 
      setFatherInfo,

      motherInfo, 
      setMotherInfo,
      
      spouseInfo, 
      setSpouseInfo,

      childsInfo, 
      setChildsInfo
      
      }}>
      {children}
    </PersonalInfoContext.Provider>
  );
};
